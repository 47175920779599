@font-face {
  font-family: "Forsvarsmakten Sans";
  src: url("eot/ForsvarsmaktenSans-Regular.eot");
  src: url("eot/ForsvarsmaktenSans-Regular.eot") format("embedded-opentype"),
    url("woff2/ForsvarsmaktenSans-Regular.woff2") format("woff2"),
    url("woff/ForsvarsmaktenSans-Regular.woff") format("woff"),
    url("ttf/ForsvarsmaktenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Forsvarsmakten Sans Bold";
  src: url("eot/ForsvarsmaktenSans-Bold.eot");
  src: url("eot/ForsvarsmaktenSans-Bold.eot") format("embedded-opentype"),
    url("woff2/ForsvarsmaktenSans-Bold.woff2") format("woff2"), url("woff/ForsvarsmaktenSans-Bold.woff") format("woff"),
    url("ttf/ForsvarsmaktenSans-Bold.ttf") format("truetype");
}
